import * as React from "react";
import { Script } from "gatsby"
const News = () => {
    return (
    <section className="news basic-block" id="news">
        <div className="container">
            <header className="news_header basic-header mb-5 pb-5">
                <span className="basic-line">
                    <span className="basic-title my-3">News</span>
                </span>
            </header>
            <div className="news_box row mx-auto mb-5">
                <div className="fb-page" data-href="https://www.facebook.com/weareinplastic" data-tabs="timeline, messages" data-width="500" data-height="500" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/weareinplastic" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/weareinplastic">We Are In Plastic</a></blockquote></div>
            </div>
        </div>
        <Script async defer crossorigin="anonymous" src="https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v8.0" nonce="KRszNjoT"/>
    </section>
    )
}
export default News;